<template>
  <transition name="fade">
    <div v-if="step > 1" class="progress-container s-wrapper"><div class="progress"><div class="progress-inner" :style="{ width: Math.ceil(step / 6 * 100) + '%' }"></div></div></div>
  </transition>
  <main class="s-expand">
    <div class="formSectionHolder">
      <transition name="fade" @enter="beforeEnter" @after-enter="afterEnter" mode="out-in">
        <section v-if="step == 1" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s1">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="step = 2">
              <h1 class="s-text-size--7xl s-margin-bottom--l">Hey there!<br/><br/>
        Let’s see what we <br class="s-hide-mobile" />
        can do together.</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <label>What's your full name?</label>
                <input v-model="name" autofocus required type="text" class="s-col-6 s-text-size--xl capitalize" placeholder="Type here..." />
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Get started<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                <div class="s-col-9 notice s-margin-top--l">
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-checkbox-circle-line s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Please make sure that the information you provide is legit because it will be reviewed by one of Spell team members</span>
                  </div>
                  <div class="s-show-phone s-margin-bottom--s" />
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-award-fill s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Applications with legit business details will have a much higher chance of getting approved</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 2" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s2">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="step = 3">
              <h1 class="s-text-size--5xl s-margin-bottom--l">Nice to meet you <span class="capitalize">{{trimmedName}}</span>!<br/><br class="s-show-phone" />
        And what's the name of your business?</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <input v-model="company" autofocus required type="text" class="s-col-6 s-text-size--xl capitalize" placeholder="Type your answer here..." />
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 3" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s3">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="step = 4">
              <h1 class="s-text-size--5xl s-margin-bottom--l">What's your work email address?</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <input v-model="email" autofocus type="email" required class="s-col-6 s-text-size--xl" placeholder="Type here..." />
                <button type="submit" class="s-button s-swatch-primary s-shape-pill s-margin-top--m">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                <div class="s-col-9 notice s-margin-top--l">
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-mail-check-line s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Please make sure that the email address you provide is valid because the access link to the Spell Demo will be sent to this email</span>
                  </div>
                  <div class="s-show-phone s-margin-bottom--s" />
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-award-fill s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Applications with emails that have the same domain as the business website have a higher chance of getting approved</span>
                  </div>
                  <div class="s-show-phone s-margin-bottom--s" />
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-error-warning-line s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Note that by clicking the “Move on” button, you agree to receive Spell newsletter.</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 4" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s4">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="step = 5">
              <h1 class="s-text-size--5xl s-margin-bottom--l">May we also have your business card? <br/><br class="s-show-phone" />
        Kidding. Just give us your LinkedIn profile.</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <input v-model="linkedin" type="text" required autofocus class="s-col-6 s-text-size--xl" placeholder="How to find you on LinkedIn?" />
                <div class="s-flex--stackable-phone--m s-items-spacing--m s-margin-top--m">
                  <button type="submit" class="s-button s-swatch-primary s-shape-pill">Move on<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                  <button @click.prevent="step = 5" class="s-button s-border--outline s-shape-pill">Skip</button>
                </div>
                <div class="s-col-9 notice s-margin-top--l">
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-error-warning-line s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Please note that skipping this info decrease your chances to receive access to Spell demo</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 5" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s5">
          <div class="s-wrapper s-relative s-expand">
            <form @submit.prevent="book">
              <h1 class="s-text-size--5xl s-margin-bottom--l">Please share the link to your company website.<br/><br class="s-show-phone" />
                If there is no website yet, give us the name.</h1>
              <div class="s-flex-direction--column--items--start-stackable-phone">
                <input v-model="web" required autofocus type="text" class="s-col-6 s-text-size--xl" placeholder="Type here..." />
                <div class="s-flex--stackable-phone--m s-items-spacing--m s-margin-top--m">
                  <button type="submit" v-bind:disabled="loading" class="s-button s-swatch-primary s-shape-pill">Request Demo<span class="icon-arrow-right-s-line s-margin-left--xs s-text--semibold"></span></button>
                  <button @click.prevent="book" v-bind:disabled="loading" class="s-button s-border--outline s-shape-pill">Skip &amp; request</button>
                </div>
                <div class="s-col-9 notice s-margin-top--l">
                  <div class="s-flex-items--center s-items-spacing--xs">
                    <span class="icon-error-warning-line s-text-size--3xl"></span>
                    <span class="s-text-size--xxs">Please note that skipping this info decrease your chances to receive access to Spell demo</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section v-else-if="step == 6" class="formSection s-swatch-black s-overflow--hidden s-flex--items animate s6 demo-requested">
          <div class="s-wrapper s-relative s-expand">
            <h1 class="s-text-size--5xl">Thanks, <span class="capitalize">{{trimmedName}}</span>!</h1>
            <h2 class="s-text-size--3xl">Spell magicians are already reviewing your request.<br/><br class="s-show-phone" />Expect an email from us within 24 hours.</h2>
            <p class="s-text-size--xl">
              Best wishes,<br/>
              🧙🏻‍♂️ Spell Team</p>
          </div>
        </section>
      </transition>
    </div>
  </main>
</template>

<script>
/* eslint-disable no-undef */
import { apiClient } from '../api/api-client'
export default {
  // You can specify layout component name here (default value is 'default')
  layout: 'form',
  data () {
    return {
      step: 1,
      name: '',
      company: '',
      email: '',
      linkedin: '',
      web: '',
      loading: false
    }
  },
  watch: {
    step (val, oldVal) {
      this.$nextTick(() => {
        const input = document.querySelector('input[autofocus]')
        if (input) {
          input.focus()
        }
      })
      if (val > oldVal) {
        if (this.replaceState) {
          window.history.replaceState({ step: this.step }, '', '')
          this.replaceState = false
        } else {
          window.history.pushState({ step: this.step }, '', '')
        }
      }
    }
  },
  mounted () {
    startBg()
    window.history.replaceState({ step: this.step }, '', '')
    window.onpopstate = (event) => {
      var step = event.state && event.state.step ? event.state.step : 1
      this.step = step
    }
  },
  methods: {
    titleCase (str) {
      var splitStr = str.toLowerCase().split(' ')
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      // Directly return the joined string
      return splitStr.join(' ')
    },

    book () {
      this.loading = true
      apiClient.post('/book', {
        name: this.titleCase(this.trimmedName),
        email: this.email,
        company: this.company,
        linkedin: this.linkedin || '-',
        web: this.web || '-',
        utm_source: window.localStorage.getItem('utm_source') || '',
        utm_medium: window.localStorage.getItem('utm_medium') || '',
        utm_content: window.localStorage.getItem('utm_content') || '',
        utm_term: window.localStorage.getItem('utm_term') || '',
        referrer: window.localStorage.getItem('referrer') || ''
      }).then(res => {
        if (res.data && res.data.id) {
          this.step = 6
          ga.getAll()[0].send('event', '/requested', '/requested', '/requested')
        }
      }).finally(() => {
        this.loading = false
      })
    },
    beforeEnter (el) {
      // document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    afterEnter (el) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
      const input = el.querySelector('input[autofocus]')
      if (input) {
        input.focus()
      }
    }
  },
  computed: {
    trimmedName () {
      return (this.name || '').trim()
    }
  }
}
</script>
<style lang="less" scoped>
  input {
    border-radius: 0;
    border: 0;
    background: transparent;
    color: #fff;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-color: #fff !important;
    padding-left: 0;
    box-shadow: none;

    .formSection &:focus {
      box-shadow: none !important;
      border-bottom-color: #ff9ffe !important;
    }

    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
    &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
    &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
    &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.8);
    }
  }
  .capitalize {
    text-transform: capitalize;

    &::placeholder {
      text-transform: none;
    }
  }
  .formSectionHolder {
    min-height: 100vh;
    overflow: hidden;
    padding: 110px 0;
  }
  .formSection {
    width: 100%;
    background: transparent !important;
    // position: fixed;
    min-height: 70vh;
    transform: translateY(0);
    transition: none;
    display: flex;
    align-items: center;

    h1,
    h2,
    h3,
    p {
      max-width: 900px;
    }
  }
  .progress-container {
    position: fixed;
    bottom: 60px;
    z-index: 22;
  }
  .progress {
    position: relative;
    height: 6px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.4);
  }
  .progress-inner {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    transition: width 0.3s ease;
  }
  .fade-enter-active,
  .fade-leave-active {
    position: fixed;
    transition: transform 600ms ease 0s, opacity 600ms ease 0s;
  }
  .fade-enter-from {
    opacity: 0;
    transform: translateY(100vh);
  }
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-100vh);
  }
  .notice {
    opacity: 1;
    font-size: 0.8rem;
  }
  button {
    cursor: pointer;
  }
  @media screen and (max-width: 560px) {
    .progress-container {
      top: 26px;
      right: 0;
      bottom: auto;
    }
    .formSection {
      align-items: flex-start;
    }
  }
</style>
